<template>
  <tr>
    <td>
      <div class="assistent--name">{{ assistent.name }}</div>
    </td>
    <td>{{ assistent.description }}</td>
    <td>{{ assistent.identifier }}</td>
    <td class="button-wrapper">
      <woot-button
        v-tooltip.top="$t('INTEGRATION_SETTINGS.ASSISTENT.EDIT.BUTTON_TEXT')"
        variant="smooth"
        size="tiny"
        color-scheme="secondary"
        icon="edit"
        @click="$emit('edit', assistent)"
      />
      <woot-button
        v-tooltip.top="$t('INTEGRATION_SETTINGS.ASSISTENT.DELETE.BUTTON_TEXT')"
        variant="smooth"
        color-scheme="alert"
        size="tiny"
        icon="dismiss-circle"
        @click="$emit('delete', assistent, index)"
      />
    </td>
  </tr>
</template>
<script>
export default {
  props: {
    assistent: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style scoped lang="scss">
.assistent--name {
  color: var(--s-700);
  font-weight: var(--font-weight-medium);
  word-break: break-word;
}

.button-wrapper {
  max-width: var(--space-mega);
  min-width: auto;

  button:nth-child(2), button:nth-child(3) {
    margin-left: var(--space-normal);
  }
}
</style>
