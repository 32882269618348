<template>
  <form class="row" @submit.prevent="onSubmit">
    <div class="box-bullets">
      <div class="bullet-diver">&nbsp;</div>
      <ul class="bullets">
        <li class="bullet-item" @click="btnNextStep(1)"><span class="bullet" :class="{'active': stepSelected == 1, 'completed': isCompletedStep1}">1</span></li>
        <li class="bullet-item" @click="btnNextStep(2)"><span class="bullet" :class="{'active': stepSelected == 2, 'completed': isCompletedStep2}">2</span></li>
        <li class="bullet-item" @click="btnNextStep(3)"><span class="bullet" :class="{'active': stepSelected == 3, 'completed': isCompletedStep3}">3</span></li>
        <li class="bullet-item" @click="btnNextStep(4)"><span class="bullet" :class="{'active': stepSelected == 4, 'completed': isCompletedStep4}">4</span></li>
      </ul>
    </div>
    <!-- PASSO 1 -->
    <div class="medium-12 columns" v-if="stepSelected === 1">
      <label :class="{ error: $v.name.$error }">
        {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.LABEL_NAME') }}
        <input
          v-model.trim="name"
          type="text"
          name="name"
          :placeholder="
            $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.PLACEHOLDER_NAME')
          "
          @input="$v.name.$touch"
        />
        <span v-if="$v.name.$error" class="message">
          {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.ERROR_NAME') }}
        </span>
      </label>

      <label :class="{ error: $v.description.$error }">
        {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.LABEL_DESCRIPTION') }}
        <textarea
          v-model.trim="description"
          name="description"
          class="textlong"
          :placeholder="$t('INTEGRATION_SETTINGS.ASSISTENT.FORM.PLACEHOLDER_DESCRIPTION')"
          @input="$v.description.$touch"
        ></textarea>
        <span v-if="$v.description.$error" class="message">
          {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.ERROR_DESCRIPTION') }}
        </span>
      </label>
    </div>
    <!-- PASSO 2 -->
    <div class="medium-12 columns" v-if="stepSelected === 2">
      <label :class="{ error: $v.instructions.$error }">
        {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.LABEL_INSTRUCTIONS') }}
        <textarea
          v-model.trim="instructions"
          name="instructions"
          class="textlong"
          :placeholder="$t('INTEGRATION_SETTINGS.ASSISTENT.FORM.PLACEHOLDER_INSTRUCTIONS')"
          @input="$v.instructions.$touch"
        ></textarea>
        <span v-if="$v.instructions.$error" class="message">
          {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.ERROR_INSTRUCTIONS') }}
        </span>
      </label>
    </div>
    <!-- PASSO 3 -->
    <div class="medium-12 columns" v-if="stepSelected === 3">
      <label>
        {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.LABEL_FILE') }}
      </label>
      <div v-if="hasAttachments">
        <attachment-preview
          :attachments="attachedFiles"
          :remove-attachment="removeAttachment"
        />
      </div>
      <file-upload
        ref="files"
        name="_file"
        :accept="acceptedFormats"
        :size="acceptedSize"
        :data="{
          direct_upload_url: '/rails/active_storage/direct_uploads',
          direct_upload: true,
        }"
        @input-file="onFileUpload"
      >
        <woot-button
          class-names="button--upload"
          icon="file-upload"
          color-scheme="secondary"
          variant="smooth"
          size="small"
        >
          <span>{{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.AD_LABEL_FILE') }}</span>
        </woot-button>

        <small>
          Formatos permitidos: <span>{{ acceptedFormats }}</span>
        </small>
      </file-upload>
    </div>
    <!-- PASSO 4 -->
    <div class="medium-12 columns" v-if="stepSelected === 4">
      <label :class="{ error: $v.model.$error }">
        {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.LABEL_MODEL') }}
        <multiselect
            v-model="model"
            :placeholder="$t('FORMS.MULTISELECT.SELECT')"
            selected-label
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            deselect-label=""
            :max-height="160"
            :options="models"
            :allow-empty="false"
          />
          <span v-if="$v.model.$error" class="message">
          {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.ERROR_MODEL') }}
        </span>
      </label>

      <label>
        {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.LABEL_TEMPERATURE') }}
        <div class="input-range">
          <input
            v-model="temperature"
            type="range"
            name="temperature"
            class="w-full"
            min="0.1"
            step="0.1"
            max="2.0"
          />
          <input
            v-model="temperature"
            type="number"
            class="input-number"
            min="0.1"
            step="0.1"
            max="2.0"
            />
        </div>
      </label>

      <label>
        {{ $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.LABEL_TOPP') }}
        <div class="input-range">
          <input
            v-model="top_p"
            type="range"
            name="top_p"
            class="w-full"
            min="0.1"
            step="0.1"
            max="1.0"
          />
          <input
            v-model="top_p"
            type="number"
            class="input-number"
            min="0.1"
            step="0.1"
            max="1.0"
            />
        </div>
      </label>
    </div>

    <div class="modal-footer footer-actions">
      <div class="medium-12 columns box-actions">
        <woot-button 
          class="button clear" 
          @click.prevent="btnPreviousStep"
          v-if="stepSelected > 1"
        >
          {{$t('INTEGRATION_SETTINGS.ASSISTENT.FORM.BTN_PREV')}}
        </woot-button>
        <woot-button 
          class="button clear" 
          @click.prevent="$emit('cancel')"
          v-else
        >
          {{$t('INTEGRATION_SETTINGS.ASSISTENT.FORM.BTN_CLOSE')}}
        </woot-button>

        <!-- Btn de Ação -->
        <woot-button 
          @click.prevent="btnNextStep(null)" 
          v-if="showBtnNextStep"
        >
          {{$t('INTEGRATION_SETTINGS.ASSISTENT.FORM.BTN_NEXT')}}
        </woot-button>
        <woot-button
          :disabled="$v.$invalid || isSubmitting"
          :is-loading="isSubmitting"
          v-else
        >
          {{$t('INTEGRATION_SETTINGS.ASSISTENT.FORM.BTN_SUBMIT')}}
        </woot-button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import { DirectUpload } from 'activestorage';
import FileUpload from 'vue-upload-component';
import alertMixin from 'shared/mixins/alertMixin';
import { checkFileSizeLimit } from 'shared/helpers/FileHelper';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import AttachmentPreview from 'dashboard/components/widgets/AttachmentsPreview';

const models = [
  'gpt-3.5-turbo',
  'gpt-3.5-turbo-1106',
  'gpt-3.5-turbo-16k',
  'gpt-4',
  'gpt-4-turbo',
  'gpt-4o',
  'gpt-4o-mini',
  'o1',
  'o1-mini',
  'o1-preview-2024-09-12'
];

const modelValidate = (value) => models.includes(value);
const convertFileToResource = (files) => {
  return files.map((file) => {
    return {
      resource: file,
      isPrivate: false,
      thumb: file.url,
      blobSignedId: file.blob_id,
    }
  });
};

export default {
  components: {
    FileUpload,
    AttachmentPreview,
  },
  mixins: [alertMixin],
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
    model: {
      required,
      modelValidate,
    },
    description: {
      maxLength: maxLength(512),
    },
    instructions: {
      required,
      maxLength: maxLength(256000),
    },
  },
  data() {
    return {
      stepSelected: null,
      name: this.value.name || '',
      description: this.value.description || '',
      models: models,
      model: this.value.model || 'gpt-4o-mini',
      temperature: this.value.temperature || 1.0,
      top_p: this.value.top_p || 1.0,
      active: this.value.active || true,
      instructions: this.value.instructions || '',
      tools: this.value.tools || [
        { type: 'code_interpreter' },
      ],
      tool_resources: this.value.tool_resources || {
        code_interpreter: {
          file_ids: [],
        }
      },
      acceptedSize: 200 * 1024 * 1024, // 200MB
      acceptedFormats: ".PDF, .DOC .JSON1 .TXT",
      attachedFiles: this.value.files ? convertFileToResource(this.value.files) : [],
      resource: null,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    showBtnNextStep() {
      return (!this.stepSelected || this.stepSelected < 4);
    },
    isCompletedStep1() {
      return (this.name && this.stepSelected > 1);
    },
    isCompletedStep2() {
      return (this.instructions && this.stepSelected > 2 && this.isCompletedStep1);
    },
    isCompletedStep3() {
      return (this.stepSelected > 3 && this.isCompletedStep2);
    },
    isCompletedStep4() {
      return (
        this.model &&
        this.temperature &&
        this.top_p &&
        this.isCompletedStep3
      );
    },
    hasAttachments() {
      return this.attachedFiles.length;
    },
    filesIds() {
      return this.attachedFiles.map((file) => file.blobSignedId);
    }
  },
  created() {
    this.stepSelected = 1; // Inicia no primeiro passo
  },
  methods: {
    btnPreviousStep() {
      if (this.stepSelected === 1) {
        this.$emit('cancel');
      } else {
        this.stepSelected -= 1;
      }
    },
    btnNextStep(step = null) {
      switch(this.stepSelected) {
        case 1:
          this.$v.name.$touch();
          this.$v.description.$touch();
          if (this.$v.name.$invalid || this.$v.description.$invalid) return;
          break;
        case 2:
          this.$v.instructions.$touch();
          if (this.$v.instructions.$invalid) return;
          break;
        case 3:
          break;
        case 4:
          this.$v.model.$touch();
          if (this.$v.model.$invalid) return;
          break;
      }
      if (step) {
        this.stepSelected = step;
      } else {
        this.stepSelected += 1;
      }
    },
    onSubmit() {
      this.$emit('submit', {
        name: this.name,
        description: this.description,
        model: this.model,
        temperature: this.temperature,
        top_p: this.top_p,
        active: this.active,
        instructions: this.instructions,
        tools: this.tools,
        tool_resources: this.tool_resources,
        files: this.filesIds,
      });
    },
    onFileUpload(file) {
      if (!file) return;

      const filename = file.name.normalize('NFD').replace(/\p{Mn}/gu, "");
      const newFile = new File([file.file], filename, { type: file.type });

      file.file = newFile;
      file.name = filename;

      const MAXIMUM_SIZE = this.acceptedSize;

      if (checkFileSizeLimit(file, MAXIMUM_SIZE)) {
        const endpoint = "/rails/active_storage/direct_uploads";
        const upload = new DirectUpload(file.file, endpoint, {
          directUploadWillCreateBlobWithXHR: xhr => {
            xhr.setRequestHeader("api_access_token",
              this.currentUser.access_token
            );
          },
        });

        upload.create((error, blob) => {
          if (error) {
            this.showAlert(error);
          } else {
            const reader = new FileReader();
            reader.readAsDataURL(file.file);
            reader.onloadend = () => {
              this.attachedFiles.push({
                resource: blob || file,
                isPrivate: false,
                thumb: reader.result,
                blobSignedId: blob ? blob.signed_id : undefined,
                path: blob ? blob.signed_id + '/' + blob.filename : undefined
              });

              this.resource = this.attachedFiles[0].resource;
            };
          }
        });
      } else {
        this.showAlert(this.$t('CONVERSATION.FILE_SIZE_LIMIT', { MAXIMUM_SIZE }));
      }
    },
    removeAttachment(itemIndex) {
      this.attachedFiles = this.attachedFiles.filter(
        (item, index) => itemIndex !== index
      );
    },
  },
};
</script>

<style scoped lang="scss">
.box-bullets {
  width: 100%;
  margin: 0 10% 20px;
  position: relative;
  .bullets {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    .bullet-item {
      z-index: 1;
      cursor: pointer;
      .bullet {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background: #fff;
        border: 1px solid #1b387c;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: #1b387c;
        &.active {
          background: #1b387c;
          color: #fff;
        }
        &.completed {
          background: #27ae60;
          border: 1px solid #27ae60;
          color: #fff;
        }
      }
    }
  }
  .bullet-diver {
    width: 100%;
    height: 1px;
    background: #1b387c;
    margin-top: 17px;
    position: absolute;
    top: 0;
  }
}
.footer-actions {
  display: flex;
  flex: 1;
  .box-actions {
    display: flex;
    justify-content: space-between;
  }
}
.input-range {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.input-number {
  width: 60px;
  height: 100%;
  margin: 0;
  margin-left: 10px;
}
.textlong {
  height: 120px;
}
</style>