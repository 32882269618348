<template>
    <div class="column content-box">
      <woot-modal-header
        :header-title="$t('INTEGRATION_SETTINGS.ASSISTENT.ADD.TITLE')"
        :header-content="
          useInstallationName(
            $t('INTEGRATION_SETTINGS.ASSISTENT.FORM.DESC'),
            globalConfig.installationName
          )
        "
      />
      <assistent-form
        :is-submitting="uiFlags.creatingItem"
        @submit="onSubmit"
        @cancel="onClose"
      />
    </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import { mapGetters } from 'vuex';
import AssistentForm from './AssistentForm';

export default {
  components: {
    AssistentForm,
  },
  mixins: [alertMixin, globalConfigMixin],
  props: {
    onClose: {
      type: Function,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      globalConfig: 'globalConfig/get',
      uiFlags: 'assistent/getUIFlags',
    }),
  },
  methods: {
    async onSubmit(assistent) {
      try {
        await this.$store.dispatch('assistent/create', { assistent });
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.ASSISTENT.ADD.API.SUCCESS_MESSAGE')
        );
        this.onClose();
      } catch (error) {
        const message =
          error.response.data.message ||
          this.$t('INTEGRATION_SETTINGS.ASSISTENT.EDIT.API.ERROR_MESSAGE');
        this.showAlert(message);
      }
    },
  },
};
</script>