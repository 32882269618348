<template>
  <div class="row content-box full-height">
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="add-circle"
      @click="openAddPopup"
    >
      {{ $t('INTEGRATION_SETTINGS.ASSISTENT.HEADER_BTN_TXT') }}
    </woot-button>

    <div class="row">
      <div class="small-8 columns with-right-space ">
        <p
          v-if="!uiFlags.fetchingList && !records.length"
          class="no-items-error-message"
        >
          <img class="no-assistant" src="/integrations/assistant/no_assistant.svg"/>
          {{ $t('INTEGRATION_SETTINGS.ASSISTENT.LIST.404') }}
        </p>
        <woot-loading-state
          v-if="uiFlags.fetchingList"
          :message="$t('INTEGRATION_SETTINGS.ASSISTENT.LOADING')"
        />

        <table
          v-if="!uiFlags.fetchingList && records.length"
          class="woot-table"
        >
          <thead>
            <th
              v-for="thHeader in $t(
                'INTEGRATION_SETTINGS.ASSISTENT.LIST.TABLE_HEADER'
              )"
              :key="thHeader"
            >
              {{ thHeader }}
            </th>
          </thead>
          <tbody>
            <assistent-row
              v-for="(assistentItem, index) in records"
              :key="assistentItem.id"
              :index="index"
              :assistent="assistentItem"
              @edit="openEditPopup"
              @delete="openDeletePopup"
            />
          </tbody>
        </table>
      </div>

      <div class="small-4 columns">
        <span
          v-dompurify-html="
            useInstallationName(
              $t('INTEGRATION_SETTINGS.ASSISTENT.SIDEBAR_TXT'),
              globalConfig.installationName
            )
          "
        />
      </div>
    </div>

    <woot-modal :show.sync="showAddPopup" :on-close="hideAddPopup">
      <new-assistent v-if="showAddPopup" :on-close="hideAddPopup" />
    </woot-modal>

    <woot-modal :show.sync="showEditPopup" :on-close="hideEditPopup">
      <edit-assistent
        v-if="showEditPopup"
        :id="selectedAssistent.id"
        :value="selectedAssistent"
        :on-close="hideEditPopup"
      />
    </woot-modal>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INTEGRATION_SETTINGS.ASSISTENT.DELETE.CONFIRM.TITLE')"
      :message="
        $t('INTEGRATION_SETTINGS.ASSISTENT.DELETE.CONFIRM.MESSAGE', {
          assistantName: selectedAssistent.name,
        })
      "
      :confirm-text="$t('INTEGRATION_SETTINGS.ASSISTENT.DELETE.CONFIRM.YES')"
      :reject-text="$t('INTEGRATION_SETTINGS.ASSISTENT.DELETE.CONFIRM.NO')"
    />
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import NewAssistent from './NewAssistent';
import EditAssistent from './EditAssistent';
import alertMixin from 'shared/mixins/alertMixin';
import globalConfigMixin from 'shared/mixins/globalConfigMixin';
import AssistentRow from './AssistentRow';

export default {
  components: {
    NewAssistent,
    EditAssistent,
    AssistentRow,
  },
  mixins: [alertMixin, globalConfigMixin],
  data() {
    return {
      loading: {},
      showAddPopup: false,
      showEditPopup: false,
      showDeleteConfirmationPopup: false,
      selectedAssistent: {},
    };
  },
  computed: {
    ...mapGetters({
      records: 'assistent/getAssistents',
      uiFlags: 'assistent/getUIFlags',
      globalConfig: 'globalConfig/get',
    }),
  },
  mounted() {
    this.$store.dispatch('assistent/get');
  },
  methods: {
    openAddPopup() {
      this.showAddPopup = true;
    },
    hideAddPopup() {
      this.showAddPopup = false;
    },
    openDeletePopup(response) {
      this.showDeleteConfirmationPopup = true;
      this.selectedAssistent = response;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    openEditPopup(webhook) {
      this.showEditPopup = true;
      this.selectedAssistent = webhook;
    },
    hideEditPopup() {
      this.showEditPopup = false;
    },
    confirmDeletion() {
      this.loading[this.selectedAssistent.id] = true;
      this.closeDeletePopup();
      this.deleteAssistent(this.selectedAssistent.id);
    },
    async deleteAssistent(id) {
      try {
        await this.$store.dispatch('assistent/delete', id);
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.ASSISTENT.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          this.$t('INTEGRATION_SETTINGS.ASSISTENT.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>

<style lang="scss">
.no-assistant {
  width: 35vh;
  height: 35vh;
  max-width: 100% !important;
}
</style>
