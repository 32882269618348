<template>
  <div class="medium-3 bg-white contact--panel">
    <ul class="tabs">
      <li
        v-for="item in Object.values(tabs)"
        :key="item.key"
        @click="setTab(item.key)"
        :class="{
          active: tab === item.key,
          disabled:
            item.key === tabs.copilot.key && !hasValidCopilotIntegration,
        }"
      >
        <span v-if="item.key === tabs.copilot.key" class="icon">✨</span>
        {{ item.label }}
      </li>
    </ul>

    <template v-if="tab === tabs.contact.key">
      <contact-info
        :contact="contact"
        :channel-type="channelType"
        @toggle-panel="onPanelToggle"
      />
      <draggable
        :list="conversationSidebarItems"
        :disabled="!dragEnabled"
        animation="200"
        class="list-group"
        ghost-class="ghost"
        handle=".drag-handle"
        @start="dragging = true"
        @end="onDragEnd"
      >
        <transition-group>
          <div
            v-for="element in conversationSidebarItems"
            :key="element.name"
            class="list-group-item"
          >
            <div
              v-if="showSidebarItem && element.name === 'conversation_actions'"
              class="conversation--actions"
            >
              <accordion-item
                :title="
                  $t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_ACTIONS')
                "
                :is-open="isContactSidebarItemOpen('is_conv_actions_open')"
                @click="
                  value => toggleSidebarUIState('is_conv_actions_open', value)
                "
              >
                <conversation-action
                  :conversation-id="conversationId"
                  :inbox-id="inboxId"
                />
              </accordion-item>
            </div>
            <div
              v-else-if="
                showSidebarItem && element.name === 'conversation_participants'
              "
              class="conversation--actions"
            >
              <accordion-item
                :title="$t('CONVERSATION_PARTICIPANTS.SIDEBAR_TITLE')"
                :is-open="isContactSidebarItemOpen('is_conv_participants_open')"
                @click="
                  value =>
                    toggleSidebarUIState('is_conv_participants_open', value)
                "
              >
                <conversation-participant
                  :conversation-id="conversationId"
                  :inbox-id="inboxId"
                />
              </accordion-item>
            </div>
            <div
              v-else-if="
                showSidebarItem && element.name === 'conversation_info'
              "
            >
              <accordion-item
                :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONVERSATION_INFO')"
                :is-open="isContactSidebarItemOpen('is_conv_details_open')"
                compact
                @click="
                  value => toggleSidebarUIState('is_conv_details_open', value)
                "
              >
                <conversation-info
                  :conversation-attributes="conversationAdditionalAttributes"
                  :contact-attributes="contactAdditionalAttributes"
                />
              </accordion-item>
            </div>
            <div
              v-else-if="
                showSidebarItem && element.name === 'contact_attributes'
              "
            >
              <accordion-item
                :title="$t('CONVERSATION_SIDEBAR.ACCORDION.CONTACT_ATTRIBUTES')"
                :is-open="
                  isContactSidebarItemOpen('is_contact_attributes_open')
                "
                compact
                @click="
                  value =>
                    toggleSidebarUIState('is_contact_attributes_open', value)
                "
              >
                <custom-attributes
                  attribute-type="contact_attribute"
                  attribute-class="conversation--attribute"
                  class="even"
                  :contact-id="contact.id"
                />
                <custom-attribute-selector
                  attribute-type="contact_attribute"
                  :contact-id="contact.id"
                />
              </accordion-item>
            </div>
            <div v-else-if="element.name === 'previous_conversation'">
              <accordion-item
                v-if="contact.id"
                :title="
                  $t('CONVERSATION_SIDEBAR.ACCORDION.PREVIOUS_CONVERSATION')
                "
                :is-open="isContactSidebarItemOpen('is_previous_conv_open')"
                @click="
                  value => toggleSidebarUIState('is_previous_conv_open', value)
                "
              >
                <contact-conversations
                  :contact-id="contact.id"
                  :conversation-id="conversationId"
                />
              </accordion-item>
            </div>
            <div v-else-if="element.name === 'history_closing_reasons'">
              <accordion-item
                :title="
                  $t('CONVERSATION_SIDEBAR.ACCORDION.HISTORY_CLOSING_REASONS')
                "
                :is-open="isContactSidebarItemOpen('is_history_reasons_open')"
                compact
                @click="
                  value =>
                    toggleSidebarUIState('is_history_reasons_open', value)
                "
              >
                <conversation-history-closing-reasons
                  :conversation-id="conversationId"
                />
              </accordion-item>
            </div>
            <woot-feature-toggle
              v-else-if="showSidebarItem && element.name === 'macros'"
              feature-key="macros"
            >
              <accordion-item
                :title="$t('CONVERSATION_SIDEBAR.ACCORDION.MACROS')"
                :is-open="isContactSidebarItemOpen('is_macro_open')"
                compact
                @click="value => toggleSidebarUIState('is_macro_open', value)"
              >
                <macros-list :conversation-id="conversationId" />
              </accordion-item>
            </woot-feature-toggle>
          </div>
        </transition-group>
      </draggable>
    </template>

    <template v-if="tab === tabs.copilot.key">
      <dashboard-app-frame
        v-if="hasValidCopilotIntegration"
        :is-visible="true"
        :config="generateUrlFrameToCopilotIntegration"
        :current-chat="currentChat"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import AccordionItem from 'dashboard/components/Accordion/AccordionItem';
import ContactConversations from './ContactConversations.vue';
import ConversationAction from './ConversationAction.vue';
import ConversationParticipant from './ConversationParticipant.vue';
import ConversationHistoryClosingReasons from './ConversationHistoryClosingReasons';
import DashboardAppFrame from 'dashboard/components/widgets/DashboardApp/Frame';

import ContactInfo from './contact/ContactInfo';
import ConversationInfo from './ConversationInfo';
import CustomAttributes from './customAttributes/CustomAttributes.vue';
import CustomAttributeSelector from './customAttributes/CustomAttributeSelector.vue';
import draggable from 'vuedraggable';
import uiSettingsMixin from 'dashboard/mixins/uiSettings';
import MacrosList from './Macros/List';

export default {
  components: {
    AccordionItem,
    ContactConversations,
    ContactInfo,
    ConversationInfo,
    CustomAttributes,
    CustomAttributeSelector,
    ConversationAction,
    ConversationParticipant,
    ConversationHistoryClosingReasons,
    draggable,
    DashboardAppFrame,
    MacrosList,
  },
  mixins: [alertMixin, uiSettingsMixin],
  props: {
    conversationId: {
      type: [Number, String],
      required: true,
    },
    inboxId: {
      type: Number,
      default: undefined,
    },
    onToggle: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      dragEnabled: true,
      conversationSidebarItems: [],
      dragging: false,
      tab: 1,
    };
  },
  computed: {
    ...mapGetters({
      currentChat: 'getSelectedChat',
      currentUser: 'getCurrentUser',
      uiFlags: 'inboxAssignableAgents/getUIFlags',
      integrationsList: 'integrations/getAppIntegrations',
    }),
    conversationAdditionalAttributes() {
      return this.currentConversationMetaData.additional_attributes || {};
    },
    channelType() {
      return this.currentChat.meta?.channel;
    },
    contact() {
      return this.$store.getters['contacts/getContact'](this.contactId);
    },
    contactAdditionalAttributes() {
      return this.contact.additional_attributes || {};
    },
    contactId() {
      return this.currentChat.meta?.sender?.id;
    },
    currentConversationMetaData() {
      return this.$store.getters[
        'conversationMetadata/getConversationMetadata'
      ](this.conversationId);
    },
    hasContactAttributes() {
      const { custom_attributes: customAttributes } = this.contact;
      return customAttributes && Object.keys(customAttributes).length;
    },
    inbox() {
      return this.$store.getters['inboxes/getInbox'](this.currentChat.inbox_id);
    },
    showSidebarItem() {
      if (
        this.inbox.lock_to_single_conversation === false &&
        this.currentChat.status == 'resolved'
      ) {
        return false;
      }
      return true;
    },
    tabs() {
      return {
        contact: { key: 1, label: this.$t('CONVERSATION_SIDEBAR.CONTACT') },
        copilot: { key: 2, label: this.$t('CONVERSATION_SIDEBAR.COPILOT') },
      };
    },
    hasValidCopilotIntegration() {
      const copilot = this.integrationsList.find(({ id }) => id === 'copilot');

      return !!copilot?.hooks?.length;
    },
    generateUrlFrameToCopilotIntegration() {
      if (!this.hasValidCopilotIntegration) {
        return null;
      }

      const { account_id, display_id } = this.currentChat;

      const copilot = this.integrationsList.find(({ id }) => id === 'copilot');

      let url = new URL(copilot.hooks[0].settings.url);
      url.searchParams.set('account_id', account_id);
      url.searchParams.set('conversation_id', display_id);
      url = url.toString();

      return [{ url, type: 'frame' }];
    },
  },
  watch: {
    conversationId(newConversationId, prevConversationId) {
      if (newConversationId && newConversationId !== prevConversationId) {
        this.getContactDetails();
      }
    },
    contactId() {
      this.getContactDetails();
    },
  },
  mounted() {
    this.conversationSidebarItems = this.conversationSidebarItemsOrder;
    this.getContactDetails();
    this.$store.dispatch('attributes/get', 0);
    if (!this.integrationsList.length) {
      this.$store.dispatch('integrations/get');
    }
  },
  methods: {
    onPanelToggle() {
      this.onToggle();
    },
    getContactDetails() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    getAttributesByModel() {
      if (this.contactId) {
        this.$store.dispatch('contacts/show', { id: this.contactId });
      }
    },
    openTranscriptModal() {
      this.showTranscriptModal = true;
    },
    onDragEnd() {
      this.dragging = false;
      this.updateUISettings({
        conversation_sidebar_items_order: this.conversationSidebarItems,
      });
    },
    setTab(key) {
      if (this.hasValidCopilotIntegration) {
        this.tab = key;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~dashboard/assets/scss/variables';

.tabs {
  display: flex;
  background-color: var(--b-200);
  padding: 0;

  li {
    text-align: center;
    padding: var(--space-one);
    width: 50%;
    list-style: none;
    cursor: pointer;

    &.active {
      color: var(--c-500);
      background-color: var(--white);
      border-radius: var(--border-radius-normal);
    }

    &.disabled {
      cursor: not-allowed;
    }
  }
}

.contact--panel {
  background: white;
  border-left: 1px solid var(--color-border);
  font-size: $font-size-small;
  overflow-y: auto;
  overflow: auto;
  position: relative;

  i {
    margin-right: $space-smaller;
  }
}

.list-group {
  .list-group-item {
    background-color: var(--white);
  }
}

::v-deep {
  .contact--profile {
    padding-bottom: var(--space-slab);
    border-bottom: 1px solid var(--color-border);
  }
  .conversation--actions .multiselect-wrap--small {
    .multiselect {
      padding-left: var(--space-medium);
      box-sizing: border-box;
    }
    .multiselect__element {
      span {
        width: 100%;
      }
    }
  }
}

.conversation--labels {
  padding: $space-medium;

  .icon {
    margin-right: $space-micro;
    font-size: $font-size-micro;
    color: #fff;
  }

  .label {
    color: #fff;
    padding: 0.2rem;
  }
}

.contact--mute {
  color: $alert-color;
  display: block;
  text-align: left;
}

.contact--actions {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-info {
  margin-top: var(--space-two);
}
.tabs .icon {
  font-size: 16px;
}
</style>
