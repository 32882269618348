import * as MutationHelpers from 'shared/helpers/vuex/mutationHelpers';
import * as types from '../mutation-types';
import assistentAPI from '../../api/assistent';

const state = {
  records: [],
  uiFlags: {
    fetchingList: false,
    creatingItem: false,
    deletingItem: false,
    updatingItem: false,
  },
};

export const getters = {
  getAssistents(_state) {
    return _state.records.sort((w1, w2) => w1.id - w2.id);
  },
  getUIFlags(_state) {
    return _state.uiFlags;
  },
};

export const actions = {
  async get({ commit }) {
    commit(types.default.SET_ASSISTENT_UI_FLAG, { fetchingList: true });
    try {
      const response = await assistentAPI.get();
      commit(types.default.SET_ASSISTENT, response.data.payload.assistents);
      commit(types.default.SET_ASSISTENT_UI_FLAG, { fetchingList: false });
    } catch (error) {
      commit(types.default.SET_ASSISTENT_UI_FLAG, { fetchingList: false });
    }
  },

  async create({ commit }, params) {
    commit(types.default.SET_ASSISTENT_UI_FLAG, { creatingItem: true });
    try {
      const response = await assistentAPI.create(params);
      const {
        payload: { assistent },
      } = response.data;
      commit(types.default.ADD_ASSISTENT, assistent);
      commit(types.default.SET_ASSISTENT_UI_FLAG, { creatingItem: false });
    } catch (error) {
      commit(types.default.SET_ASSISTENT_UI_FLAG, { creatingItem: false });
      throw error;
    }
  },

  update: async ({ commit }, { id, ...updateObj }) => {
    commit(types.default.SET_ASSISTENT_UI_FLAG, { updatingItem: true });
    try {
      const response = await assistentAPI.update(id, updateObj);
      commit(types.default.UPDATE_ASSISTENT, response.data.payload.assistent);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(types.default.SET_ASSISTENT_UI_FLAG, { updatingItem: false });
    }
  },

  async delete({ commit }, id) {
    commit(types.default.SET_ASSISTENT_UI_FLAG, { deletingItem: true });
    try {
      await assistentAPI.delete(id);
      commit(types.default.DELETE_ASSISTENT, id);
      commit(types.default.SET_ASSISTENT_UI_FLAG, { deletingItem: false });
    } catch (error) {
      commit(types.default.SET_ASSISTENT_UI_FLAG, { deletingItem: false });
      throw error;
    }
  },
};

export const mutations = {
  [types.default.SET_ASSISTENT_UI_FLAG](_state, data) {
    _state.uiFlags = {
      ..._state.uiFlags,
      ...data,
    };
  },
  [types.default.SET_ASSISTENT]: MutationHelpers.set,
  [types.default.ADD_ASSISTENT]: MutationHelpers.create,
  [types.default.DELETE_ASSISTENT]: MutationHelpers.destroy,
  [types.default.UPDATE_ASSISTENT]: MutationHelpers.update,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
