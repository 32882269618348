<template>
  <section class="board-conversations">
    <div class="table-container">
      <ve-table
        :columns="columns"
        :table-data="paginatedList"
        :fixed-header="true"
        :border-around="true"
      >
      </ve-table>
    </div>
    <div class="pagination-container">
      <TableFooter
        :currentPage="currentPage"
        :pageSize="itemsPerPage"
        :totalCount="count"
        @page-change="onPageChange"
      />
    </div>
  </section>
</template>

<script>
import { VeTable } from 'vue-easytable';
import TableFooter from 'dashboard/components/widgets/TableFooter';
import ConversationApi from '../../../../api/inbox/conversation.js';

export default {
  components: {
    VeTable,
    TableFooter,
  },
  props: {
    conversationInbox: {
      type: [String, Number],
      default: 0,
    },
    teamId: {
      type: [String, Number],
      default: 0,
    },
    label: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      count: 0,
      paginatedList: [],
      itemsPerPage: 15,
      currentPage: 1,
      refreshInterval: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          field: 'contactName',
          key: 'contactName',
          title: this.$t('PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.NAME'),
          renderBodyCell: ({ row }) => (
            <router-link
              to={`/app/accounts/${this.$route.params.accountId}/contacts/${row.id}`}
              class="contact-name-link"
            >
              {row.contactName}
            </router-link>
          ),
        },
        {
          field: 'phone',
          key: 'phone',
          title: this.$t(
            'PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.PHONE_NUMBER'
          ),
        },
        {
          field: 'priority',
          key: 'priority',
          title: this.$t('PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.PRIORITY'),
          renderBodyCell: ({ row }) => (
            <span class={this.getPriorityClass(row.priority)}>
              {row.priority}
            </span>
          ),
        },

        {
          field: 'lastMessageDate',
          key: 'lastMessageDate',
          title: this.$t(
            'PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.LAST_MESSAGE_DATE'
          ),
        },
        {
          field: 'waitingTime',
          key: 'waitingTime',
          title: this.$t(
            'PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.WAITING_TIME'
          ),
        },
        {
          field: 'lastMessage',
          key: 'lastMessage',
          title: this.$t(
            'PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.LAST_MESSAGE_TEXT'
          ),
        },
        {
          field: 'team',
          key: 'team',
          title: this.$t(
            'PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.ASSIGNED_TEAM'
          ),
        },
        {
          field: 'assignee',
          key: 'assignee',
          title: this.$t(
            'PRIORITY_CONTACTS_PAGE.LIST.TABLE_HEADER.ASSIGNED_AGENT'
          ),
        },
      ];
    },
    conversationFilters() {
      return {
        inboxId: this.conversationInbox || undefined,
        status: this.status,
        page: this.currentPage,
        labels: this.label ? [this.label] : undefined,
        teamId: this.teamId || undefined,
        conversationType: 'priority',
      };
    },
  },
  mounted() {
    this.resetAndFetchData();
    this.startAutoRefresh();
  },
  beforeUnmount() {
    this.stopAutoRefresh();
  },
  methods: {
    async resetAndFetchData() {
      this.currentPage = 1;
      await this.fetchCountConversation();
    },
    async fetchCountConversation() {
      try {
        const {
          data: { data },
        } = await ConversationApi.get({
          ...this.conversationFilters,
          page: this.currentPage,
          per_page: this.itemsPerPage,
          sortBy: 'sort_on_priority',
        });

        this.count = data.meta.all_count;
        this.paginatedList = data.payload.map(item => ({
          id: item.meta?.sender?.id,
          contactName: item.meta?.sender?.name || '---',
          phone: item.meta?.sender?.phone_number || '---',
          priority: this.mapPriority(item.priority),
          lastMessage: this.truncateText(
            item.last_non_activity_message?.content || '---',
            40
          ),
          waitingTime:
            this.formatWaitingTime(item.waiting_time_in_seconds) || '00:00:00',
          team: item.meta?.team?.name || '---',
          assignee: item.meta?.assignee?.available_name || '---',
          lastMessageDate: this.formatDateToLocalTime(item.last_message_date),
        }));
      } catch (error) {
        console.error(error);
      }
    },
    formatDateToLocalTime(dateString) {
      if (!dateString) return '---';

      const isoFormattedDate = dateString.replace(
        /(\d{2})\/(\d{2})\/(\d{4}) (\d{2}:\d{2}:\d{2})/,
        '$3-$2-$1T$4'
      );
      const date = new Date(isoFormattedDate);

      if (isNaN(date.getTime())) {
        return '---';
      }

      const localDate = new Date(date.getTime() - 3 * 3600000);
      const datePart = localDate.toLocaleDateString('pt-BR');
      const timePart = localDate.toLocaleTimeString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
      });

      return `${datePart} ${timePart}`;
    },
    truncateText(text, maxLength) {
      if (!text || text.length <= maxLength) return text || '---';
      return text.slice(0, maxLength - 3) + '...';
    },
    onPageChange(page) {
      this.currentPage = page;
      this.fetchCountConversation();
    },
    formatWaitingTime(seconds) {
      if (!seconds) return '00:00:00';
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return `${hours.toString().padStart(2, '0')}:${minutes
        .toString()
        .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    },
    mapPriority(priority) {
      const mapping = {
        urgent: this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.URGENT'),
        high: this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.HIGH'),
        medium: this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.MEDIUM'),
        low: this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.LOW'),
      };
      return (
        mapping[priority] ||
        this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.UNDEFINED')
      );
    },
    getPriorityClass(priority) {
      const priorityMapping = {
        [this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.URGENT')]: 'priority-urgent',
        [this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.HIGH')]: 'priority-high',
        [this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.MEDIUM')]: 'priority-medium',
        [this.$t('PRIORITY_CONTACTS_PAGE.PRIORITY.LOW')]: 'priority-low',
      };
      return priorityMapping[priority] || 'priority-undefined';
    },
    startAutoRefresh() {
      this.refreshInterval = setInterval(() => {
        this.fetchCountConversation();
      }, 300000);
    },
    stopAutoRefresh() {
      if (this.refreshInterval) {
        clearInterval(this.refreshInterval);
        this.refreshInterval = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.priority-urgent {
  background-color: var(--urgent-color);
}
.priority-high {
  background-color: var(--high-color);
}
.priority-medium {
  background-color: var(--medium-color);
}
.priority-low {
  background-color: var(--low-color);
}
.priority-undefined {
  background-color: var(--none-color);
}
.priority-urgent,
.priority-high,
.priority-medium,
.priority-low,
.priority-undefined {
  display: inline-block;
  color: var(--white);
  padding: var(--space-smaller) var(--space-slab);
  border-radius: var(--space-small);
  font-weight: var(--font-weight-bold);
  text-align: center;
}
.board-conversations::v-deep {
  .ve-table {
    thead.ve-table-header .ve-table-header-tr .ve-table-header-th {
      font-size: var(--font-size-mini);
      padding: var(--space-small) var(--space-two);
    }
    tbody.ve-table-body .ve-table-body-tr .ve-table-body-td {
      padding: var(--space-slab) var(--space-two);

      .inbox--name {
        margin: 0;
      }
    }
  }
}
.table-container {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: auto;
}
.pagination-container {
  position: sticky;
  bottom: 0;
  padding: var(--space-slab);
  z-index: 10;
}
.board-conversations {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>