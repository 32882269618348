import AppContainer from './Dashboard';
import settings from './settings/settings.routes';
import conversation from './conversation/conversation.routes';
import { routes as dashboardAppsRoutes } from './dashboard-apps/dashboard-apps.routes';
import { routes as blockAccessUserAccount } from './block-access-user-account/block-access-user-account.routes.js';
import { routes as searchRoutes } from '../../modules/search/search.routes';
import { routes as contactRoutes } from './contacts/routes';
import { routes as notificationRoutes } from './notifications/routes';
import { routes as boardRoutes } from './boards/board.routes';
import { frontendURL } from '../../helper/URLHelper';
import helpcenterRoutes from './helpcenter/helpcenter.routes';
import { routes as priorityContactRoutes } from './priority-contacts/routes';
import { routes as embeddedBuilderRoutes } from './embedded-builder/routes.js';

const Suspended = () => import('./suspended/Index');
const WhatsappQrcode = () => import('./whatsapp/Index');

export default {
  routes: [
    ...helpcenterRoutes.routes,
    {
      path: frontendURL('accounts/:account_id'),
      component: AppContainer,
      children: [
        ...conversation.routes,
        ...settings.routes,
        ...contactRoutes,
        ...searchRoutes,
        ...notificationRoutes,
        ...boardRoutes,
        ...blockAccessUserAccount,
        ...dashboardAppsRoutes,
        ...priorityContactRoutes,
        ...embeddedBuilderRoutes
      ],
    },
    {
      path: frontendURL('accounts/:accountId/suspended'),
      name: 'account_suspended',
      roles: ['administrator', 'agent', 'supervisor'],
      component: Suspended,
    },
    {
      path: frontendURL('whatsapp/:inboxId/qrcode', null, true),
      name: 'whatsapp_qrcode',
      component: WhatsappQrcode,
    },
  ],
};
