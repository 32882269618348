<template>
  <div class="embedded-builder-wrap">
    <div v-if="isBlocked">
      <p>
        {{ $t('BUILDER_PAGE.EMBEDDED_ACCESS_BLOCKED') }} <br />
        <a :href="baseUrl" target="_blank" rel="noopener noreferrer">
          {{ $t('BUILDER_PAGE.OPEN_IN_NEW_TAB') }}
        </a>
      </p>
    </div>
    <iframe
      v-else
      ref="builderIframe"
      :src="baseUrl"
      title="Embedded Builder"
      class="embedded-iframe"
      @load="onIframeLoad"
    ></iframe>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isBlocked: false,
      baseUrl: 'https://builder.converx.app',
    };
  },
  computed: {
    dashboardAppContext() {
      return {
        currentAgent: this.currentAgent,
        accountId: this.$store.getters.getCurrentAccountId,
      };
    },
    currentAgent() {
      const { id, name, email } = this.$store.getters.getCurrentUser;
      return { id, name, email };
    },
  },
  mounted() {
    this.checkIframeBlocked();
  },
  methods: {
    checkIframeBlocked() {
      this.$nextTick(() => {
        setTimeout(() => {
          try {
            const iframe = this.$refs.builderIframe;
            if (
              !iframe ||
              !iframe.contentWindow ||
              !iframe.contentWindow.location
            ) {
              this.isBlocked = true;
            }
          } catch (e) {
            this.isBlocked = true;
          }
        }, 1000);
      });
    },
    onIframeLoad() {
      const iframe = this.$refs.builderIframe;
      if (iframe && iframe.contentWindow) {
        const eventData = {
          event: 'appContext',
          data: this.dashboardAppContext,
        };
        iframe.contentWindow.postMessage(JSON.stringify(eventData), '*');
      }
    },
  },
};
</script>

<style scoped>
.embedded-builder-wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.embedded-iframe {
  width: 100%;
  height: 100vh;
  border: none;
}
</style>
