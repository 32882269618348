import EmbeddedBuilderView from './components/EmbeddedBuilderView';
import { frontendURL } from '../../../helper/URLHelper';

export const routes = [
    {
      path: frontendURL('accounts/:accountId/embedded-builder'),
      name: 'embedded_builder',
      roles: ['administrator'],
      component: EmbeddedBuilderView,
    },
];
